@import '@angular/material/theming';
@include mat-core();

$bv-brand: (
    50: #e3f1fb,
    100: #baddf7,
    200: #8fc8f2,
    300: #64b2ec,
    400: #43a2e9,
    500: #2293e5,
    600: #1c85d8,
    700: #1473c6,
    800: #0d63b4,
    900: #004695,
    A100: #ffffff,
    A200: #dde6f3,
    A400: #6992c9,
    A700: #38649d,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);

$bv-orange: (
    50: #fff7e0,
    100: #ffe9b1,
    200: #fedb7f,
    300: #fecf4c,
    400: #fdc324,
    500: #fcb900,
    600: #fcab00,
    700: #fb9800,
    800: #fb8702,
    900: #fa6706,
    A100: #ffffff,
    A200: #fff7f4,
    A400: #fc8259,
    A700: #f44205,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);


$my-app-primary: mat-palette($bv-brand, 900);
$my-app-accent:  mat-palette($bv-orange, 500);
$my-app-warn:    mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);

.alternate-theme {
  $alternate-primary: mat-palette($mat-blue-grey);
  $alternate-accent:  mat-palette($mat-pink, 500, 900, A100);
  $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
  @include angular-material-theme($alternate-theme);
}